:root {
  --base: 220;
  --hdr: hsl(calc(var(--base) - 1), 21%, 13%); /* Header Background Color */
  --bkg: hsl(var(--base), 20%, 9%); /* Background Color */
  --frg: hsl(var(--base), 20%, 17%); /* Foreground Color */
  --frg-l: hsl(var(--base), 20%, 22%); /* Light Foreground Color */
  --frg-ll: hsl(var(--base), 20%, 28%);
  --hgl: hsl(calc(var(--base) - 10), 100%, 40%); /* Highlight Color */

  --htx: #666; /* Header Text Color */
  --txt: #888888; /* Text Color */

  --screen-m: 912px; /* Screen breakpoints */
  --screen-s: 608px;

  --lwidth: 908px; /* Widths for homesearch */
  --mwidth: 604px;
  --swidth: 300px;

  --max-links: 7; /* Default value */
  --section-padding: 10px;
  --link-text-height: 20px;
  --link-margin: 5px;
  --link-height: calc(var(--link-text-height) + var(--link-margin));

  --homesearch-height: 400px;
  --section-width: 300px;
  --section-height: calc(
    calc(var(--max-links) + 1) * var(--link-height) + var(--section-padding)
  );
}

@font-face {
  /* LICENSE: "font/SIL Open Font License.txt" font by Julieta Ulanovsky */
  src: url(font/Montserrat-Regular.otf);
  font-family: Montserrat;
}

html,
body {
  background-color: var(--bkg);
  font-family: Montserrat;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  margin: 0;
}

@media screen and (max-width: 912px) {
  html, body {
    height: unset;
  }
}

body {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Root container styling */

div#wiper {
  opacity: 0 !important;
  z-index: -1 !important;
}

div#root {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: space-evenly;
  align-items: center;
}

/* Corner links styling */

.left-corner {
  position: absolute;
  top: 5px;
  left: 13px;
}

.right-corner {
  position: absolute;
  top: 5px;
  right: 15px;
}

div#bottom-link-wrapper {
  position: relative;
  width: 100%;
}

.top-link {
  text-decoration: none;
  padding: 0.25em 0em;
  color: var(--htx);
}

.top-link:hover {
  color: var(--txt);
}

.top-link i {
  width: 1.3em;
  height: 1.3em;
  color: rgb(88, 88, 88);
}

.top-link i:hover {
  width: 1.3em;
  height: 1.3em;
  color: var(--txt);
}

@media only screen and (max-width: 1175px) {
  .top-link {
    font-size: 18px;
  }

  .left-corner {
    top: 10px;
  }

  .right-corner {
    top: 10px;
  }
}
